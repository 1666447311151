import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Layout } from '../old-components'

import { FC } from 'react'
import { ILandingPageQuery } from '../types/landing-page'
import { Sections } from '../components/sections/sections'

type BikesPageProps = PageProps<ILandingPageQuery>

const BikesPage: FC<BikesPageProps> = ({ data, path }) => {
  const { contentfulLandingPage } = data
  return (
    <Layout title={contentfulLandingPage.pageTitle}>
      <Sections sections={contentfulLandingPage.sections} />
    </Layout>
  )
}

export const query = graphql`
  query BikePageQuery($language: String) {
    contentfulLandingPage(
      slug: { eq: "bikes" }
      node_locale: { eq: $language }
    ) {
      internalName
      pageTitle
      sections {
        __typename
        ... on ContentfulSectionText {
          contentful_id
          id
          title
          text {
            raw
          }
        }
        ... on ContentfulSectionBikeList {
          title
          description {
            raw
          }
          bikes {
            internalName
            model
            brand
            colour
            description {
              raw
            }
            node_locale
            image {
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            sizes
            halfDayPrice
            fullDayPrice
          }
        }
      }
    }
  }
`

export default BikesPage
